import React from 'react'
import { Link } from 'gatsby'
import { EnvelopeIcon } from '@heroicons/react/24/outline'

// utils
import { CONTACT } from '@constants/routes'

// hooks
import useLayoutEffects from '@hooks/useLayoutEffects'

// components
import MailerLiteEmailForm from '@components/Layout/MailerLiteEmailForm'

export default function Layout({ children, location }) {
    useLayoutEffects(location)

    const { pathname } = location
    const showForms = !pathname.includes('signup') && !pathname.includes('thanks') && !pathname.includes('contact')

    return (
        <>
            <>{children}</>
            {showForms && (
                <>
                    <Link
                        to={CONTACT}
                        className='fixed bottom-5 right-5 z-50 hidden rounded-full rounded-br-none bg-haze ring-2 ring-white xl:block'
                    >
                        <span className='absolute right-0.5 top-0.5 inline-flex size-3 rounded-full bg-eastern' />
                        <span className='absolute right-0.5 top-0.5 inline-flex size-3 animate-ping rounded-full bg-eastern opacity-75' />
                        <EnvelopeIcon className='m-3 size-7 stroke-white p-0.5' />
                    </Link>
                    <MailerLiteEmailForm location={location} />
                </>
            )}
        </>
    )
}
